import React from "react";
import ReportForm from "./ReportForm";
import ReportView from "./ReportView";
import {authServer, cacheServer, reportServer} from "./conf";

function filterFunction() {
    let input, filter, a, i, div, txtValue;
    input = document.getElementById("cacheInput");
    filter = input.value.toUpperCase();
    div = document.getElementById("cacheDropdown");
    a = div.getElementsByTagName("button");
    for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = "";
        } else {
            a[i].style.display = "none";
        }
    }
}

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {report: null, token: props.token, credits: 0, cache: null, error: null};

        this.onCacheClick = this.onCacheClick.bind(this)
    }

    componentDidMount() {
        this.credits()
        this.cached()
    }

    generateReport(address) {
        let formData = new FormData();
        formData.append('address', address);
        formData.append('api_token', this.state.token)

        fetch(`${reportServer}/report`, {
            method: 'POST',
            headers: {
                'Access-Control-Request-Method': 'POST'
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
		if(data.status === 'ok') {
			this.setState({report: data.report, error: null});
			this.setReport(data.report).then(r => {
			    this.credits()
			    this.cached()
			    document.getElementById('reportSubmitBtn').removeAttribute('disabled')
			})
		} else {
	            this.setState({report: null, error: data.msg});
		    document.getElementById('reportSubmitBtn').removeAttribute('disabled')
		}
            })
            .catch((err) => {
                this.setState({report: null, error: err.msg});
                console.log(err.message);
            });
    }

    credits() {
        let formData = new FormData();
        formData.append('api_token', this.state.token)

        fetch(`${authServer}/credits`, {
            method: 'POST',
            headers: {
                'Access-Control-Request-Method': 'POST'
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({credits: data.credits});
            })
            .catch((err) => {
                this.setState({credits: 0});
                console.log(err.message);
            });
    }

    cached() {
        let formData = new FormData();
        formData.append('api_token', this.state.token)

        fetch(`${cacheServer}/cache/get`, {
            method: 'POST',
            headers: {
                'Access-Control-Request-Method': 'POST'
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({cache: data.cache});
            })
            .catch((err) => {
                this.setState({cache: null});
                console.log(err.message);
            });
    }

    CachedSearch({cache, onClick}) {
        return <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Previous
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    cache.map(c =>
                        <button className="dropdown-item" key={c.id} onClick={e => onClick(c)}>{c.address}</button>
                    )
                }
            </div>
        </div>;
    }

    async setReport(report) {
        await this.setState({report: null})
        await this.setState({report: report})
    }

    async onCacheClick(record) {
        await this.setReport(record.report)
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <h1>
                        EPC Report Generator
                    </h1>
                    <h3>({this.state.credits}) credits</h3>
                    {this.state.cache != null && <this.CachedSearch cache={this.state.cache} onClick={this.onCacheClick}/>}
                    <ReportForm onSubmit={e => this.generateReport(e)}></ReportForm>
                    {this.state.report != null && <ReportView report={this.state.report}/>}
                    {this.state.error != null && <div class="alert alert-danger" role="alert">{this.state.error}</div>}
                </header>
            </div>
        );
    }
}

export default MainPage;
