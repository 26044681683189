import React from "react";

class ReportForm extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = props.onSubmit;

        this.state = {value: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {    this.setState({value: event.target.value});  }
    handleSubmit(event) {
        document.getElementById('reportSubmitBtn').setAttribute("disabled","disabled");
        this.onSubmit(this.state.value);
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>
                        Address:
                        <input type="text" className="form-control" value={this.state.value}
                               onChange={this.handleChange}/>
                    </label>
                </div>

                <button id="reportSubmitBtn" className="btn btn-primary" type="submit">Create Report</button>
            </form>
    );
    }
    }

    export default ReportForm;