import React from "react";
import {authServer} from "./conf";


class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {user: '', pass: ''};

        this.onLogin = this.props.onLogin;

        this.handleUser = this.handleUser.bind(this);
        this.handlePass = this.handlePass.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUser(event) {    this.setState({user: event.target.value});  }
    handlePass(event) {    this.setState({pass: event.target.value});  }
    handleSubmit(event) {
        console.log(`User: ${this.state.user}, pass: ${this.state.pass}`)

        let formData = new FormData();
        formData.append('username', this.state.user);
        formData.append('password', this.state.pass);

        fetch(`${authServer}/login`, {
            method: 'POST',
            headers: {
                'Access-Control-Request-Method': 'POST'
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if(data.status === 'ok') {
                    this.onLogin(data);
                } else {
                    console.log(data.message);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });

        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="usernameBox">Username:</label>
                    <input id="usernameBox" className="form-control" type="text" value={this.state.user} onChange={this.handleUser}/>
                </div>
                <div className="form-group">
                    <label htmlFor="passwordBox"> Password:</label>
                    <input id="passwordBox" className="form-control" type="password" value={this.state.pass} onChange={this.handlePass}/>
                </div>

                <button type="submit" className="btn btn-primary">Log In</button>
            </form>
        );
    }
}

export default LoginPage;