import { useState } from 'react';

import './App.css';

import MainPage from "./MainPage";
import LoginPage from "./LoginPage";

var token;

function App() {
  const [token, setToken] = useState();

  if(token)
    return <MainPage token={token}/>
  else
    return <LoginPage onLogin={e => setToken(e.jwt)}/>;
}

export default App;
