import React from "react";

class ReportView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {report: props.report};
    }

    render() {
        let report = this.state.report;

        let mapLinks, planning, epc, certs, rightmove, landRegistry, fensa;

        if(report.planning_link) {
            planning = <a href={report.planning_link}>Planning History</a>
        }

        if(report.epc_certificates) {
            certs = <ul>
                {report.epc_certificates.map(cert => {
                    return <a key={cert.url} href={cert.url}>Certificate</a>
                })}
            </ul>
        }

        if(report.fensa) {
            fensa = report.fensa;
        }

        if(report.map_links) {
            mapLinks = <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Url</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Property Location</td>
                        <td><a href={report.map_links[0]}>{report.map_links[0]}</a></td>
                    </tr>
                    <tr>
                        <td>Satellite View</td>
                        <td><a href={report.map_links[1]}>{report.map_links[1]}</a></td>
                    </tr>
                    <tr>
                        <td>Street View</td>
                        <td><a href={report.map_links[2]}>{report.map_links[2]}</a></td>
                    </tr>
                </tbody>
            </table>;
        }

        if(report.epc_details) {
            epc = <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Report</th>
                    <th>Type</th>
                </tr>
                </thead>
                <tbody>
                {
                    report.epc_details.map(def => {
                        return (
                          <tr>
                            <td>{def.inspectiondate}</td>
                            <td><a href={def.url}>{def.url}</a></td>
                            <td>{def.property_type}</td>
                          </tr>
                        );
                    })
                }
                </tbody>
            </table>;
        }

        if(report.rightmove_listings) {
            rightmove = <table>
                <thead>
                <tr>
                    <th>URL</th>
                    <th>Image</th>
                    <th>Floor Plan</th>
                </tr>
                </thead>
                <tbody>
                {
                    report.rightmove_listings.map(listing => {
                        return <tr>
                            <td><a href={listing.url}>Url</a></td>
                            <td>{listing.floorplan? <a href={listing.floorplan}>Floor Plan</a> : <p>No floor plan</p>}</td>
                            <td>{listing.image? <a href={listing.image}>Image</a> : <p>No image</p>}</td>
                        </tr>;
                    })
                }
                </tbody>
            </table>;
        }

        if(report.landregistry_sales) {
            landRegistry = <table>
                <thead>
                <tr>
                    <th>Sale Date</th>
                    <th>Property Type</th>
                    <th>Holding Type</th>
                    <th>New Build?</th>
                </tr>
                </thead>
                <tbody>
                {
                    report.landregistry_sales.map(sale => {
                        return <tr>
                            <td>{sale.date}</td>
                            <td>{sale.property_type}</td>
                            <td>{sale.holding_type}</td>
                            <td>{sale.new_build === true? "Yes" : "No"}</td>
                        </tr>;
                    })
                }
                </tbody>
            </table>;
        }

        return <div id="reportbox">
            <div id="report_item">
                <h3>Map Links</h3>
                {mapLinks}
            </div>

            <div id="report_item">
                <h3>Planning Permission</h3>
                {planning}
            </div>

            <div id="report_item">
                <h3>Epc Reports</h3>
                {epc}
            </div>

            <div id="report_item">
                <h3>Epc Certificates</h3>
                {certs}
            </div>

            <div id="report_item">
                <h3>Rightmove Listings</h3>
                {rightmove}
            </div>

            <div id="report_item">
                <h3>Landregistry Date</h3>
                {landRegistry}
            </div>

            <div id="report_item">
                <h3>Fensa Report</h3>
                <div dangerouslySetInnerHTML={{__html: fensa}}></div>
            </div>
        </div>;
    }
}

export default ReportView;